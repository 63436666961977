import { Button, FormControlLabel, Switch } from '@material-ui/core';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { api } from '../../../../Services/api';
import { tiraMascaraCNPJ } from '../../../../utils/cnpj';
import { Loading } from '../../../Loading/Loading';
import { CriaBancoModal } from '../../../Modal/CriaBanco/CriaBancoModal';
import { GeraSenha } from '../../../Modal/GeraSenha';
import { SubDominio } from '../../../Modal/SubDominio';
import { CardSystem } from '../style';

export const CardSistema = ({
  sistema,
  dadosFormulario,
  setDadosFormulario,
}) => {
  const [situacao, setSituacao] = useState(sistema.id_situacao === 1 ? 1 : 0);

  const [openModal, setOpenModal] = useState(false);
  const [statusModalSubDominio, setStatusModalSubDominio] = useState(false);
  const [openModalSubDominio, setOpenModalSubDominio] = useState(false);
  const [openModalGeraSenha, setOpenModalGeraSenha] = useState(false);

  const [liberaSenha, setLiberaSenha] = useState(
    sistema.id_situacao === 1 && sistema.id_cliente_sistema !== null
      ? true
      : false,
  );

  const [situacaobtnFechar, setSituacaobtnFechar] = useState(true);

  // eslint-disable-next-line
  const [situacaobtnFecharSenha, setSituacaobtnFecharSenha] = useState(true);

  const [situacaoBotaoBanco, setSituacaoBotaoBanco] = useState(
    sistema.id_cliente_sistema === null
      ? 'btn_cria_sistema'
      : 'btn_sistema_ativo',
  );

  const [situacaoBotaoSenha, setSituacaoBotaoSenha] = useState(
    sistema.id_cliente_sistema === null
      ? 'btn_cria_sistema'
      : 'btn_black_sistema',
  );

  const [estadoRequisicao, setEstadoRequisicao] = useState(0);

  // eslint-disable-next-line
  const [status, setStatus] = useState([
    <Loading
      texto="Aguarde um momento enquanto o sistema gera o banco de dados para este cliente!"
      statusConfig={0}
    />,
    <Loading
      texto="O banco de dados foi gerado com sucesso! Agora você pode continuar a completar o cadastro do cliente."
      img="/images/check.svg"
      statusConfig={1}
    />,
    <Loading
      texto="Erro ao criar o Banco de Dados"
      img="/images/block.svg"
      statusConfig={2}
    />,
  ]);

  useEffect(() => {
    setLiberaSenha(
      situacao && sistema.id_cliente_sistema !== null ? true : false,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [situacao]);

  function getSituacao() {
    return sistema.id_situacao === 1 ? 'Ativo' : 'Inativo';
  }

  function toggleModal() {
    setOpenModal((prev) => !prev);
    statusModalSubDominio === false
      ? setOpenModalSubDominio(false)
      : setOpenModalSubDominio(true);
  }

  function toggleModalSubDominio() {
    setOpenModalSubDominio((prev) => !prev);
  }

  function toggleModalGeraSenha() {
    setOpenModalGeraSenha((prev) => !prev);
  }

  async function toggleChecked() {
    if (sistema.id_cliente_sistema) {
      await api.patch(`clientesistema/${sistema.id_cliente_sistema}/situacao`);
    }
    setSituacao(!situacao);
  }

  async function createTheOneInitialData(config) {
    const pessoa = {
      nome: dadosFormulario.razao_social,
      idTipoPessoa: 2,
      email: dadosFormulario.email,
      telefone: dadosFormulario.telefone,
      pessoaJuridica: {
        cnpj: dadosFormulario.cnpj,
        nomeFantasia: dadosFormulario.fantasia,
        inscricaoEstadual: dadosFormulario.inscricao_estadual,
        inscricaoMunicipal: dadosFormulario.inscricao_municipal,
        cnae: dadosFormulario.cnae,
        responsavel: dadosFormulario.responsavel,
      },
    };

    const newConfig = {
      headers: {
        Authorization: config.headers.Authorization,
        cnpj: dadosFormulario.cnpj,
      },
    };

    await axios
      .post(`${sistema.url_servidor}/v1/api/pessoa/adm`, pessoa, newConfig)
      .then(() => {
        toast.success('Dados do cliente salvos!');
        return;
      })
      .catch((err) => {
        toast.error(err.message);
        return;
      });
  }

  function criaBanco() {
    const config = {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    };

    if (sistema.descricao_sistema === 'The One') {
      toast.warn('Função em desenvolvimento!');
      toggleChecked();
      return;
    }

    setOpenModal(true);

    axios
      .post(
        `${sistema.url_servidor}/v1/api/estrutura`,
        { cnpj: tiraMascaraCNPJ(dadosFormulario.cnpj) },
        config,
      )
      .then(async (response) => {
        if (response.status === 201) {
          await api
            .post('clientesistema', {
              id_pessoa_cliente: dadosFormulario.id_cliente,
              id_sistema: sistema.id_sistema,
              id_situacao_cadastro: 1,
            })
            .then(async (res) => {
              sistema.id_cliente_sistema = res.data.id;
              switch (sistema.descricao_sistema) {
                case 'Pedido Web':
                  setEstadoRequisicao(1);
                  setStatusModalSubDominio(true);
                  break;
                case 'Phoenix':
                  await createTheOneInitialData(config);
                  setEstadoRequisicao(1);
                  break;
                default:
                  toast.warn('Função em desenvolvimento!');
                  toggleChecked();
              }
              setLiberaSenha(true);
              setSituacaoBotaoBanco('btn_sistema_ativo');
              setSituacaobtnFechar(false);
            })
            .catch(() => {
              setEstadoRequisicao(2);
              setSituacaobtnFechar(false);
            });
        } else {
          setEstadoRequisicao(2);
          setSituacaobtnFechar(false);
        }
      })
      .catch(() => {
        setEstadoRequisicao(2);
        setSituacaobtnFechar(false);
      });
  }

  function geraSenha() {
    setOpenModalGeraSenha(true);
    setSituacaoBotaoSenha('btn_black_sistema');
    setSituacaobtnFecharSenha(false);
  }

  return (
    <CardSystem>
      <h3>{sistema.descricao_sistema}</h3>
      <FormControlLabel
        className="ativo"
        control={
          <Switch color="primary" checked={situacao} onChange={toggleChecked} />
        }
        label={getSituacao()}
      />
      <Button
        variant="contained"
        className={`gerabanco  ${situacaoBotaoBanco}`}
        disabled={!situacao}
        onClick={() =>
          sistema.id_cliente_sistema === null ? criaBanco() : null
        }
      >
        {sistema.id_cliente_sistema === null
          ? 'Gerar banco de dados ' + sistema.descricao_sistema
          : 'Banco do ' + sistema.descricao_sistema + ' criado'}
      </Button>
      <Button
        variant="contained"
        className={`gerasenha ${situacaoBotaoSenha}`}
        disabled={!liberaSenha}
        onClick={() => geraSenha()}
      >
        Gerar Senha {sistema.descricao_sistema}
      </Button>
      <CriaBancoModal
        open={openModal}
        toggleModal={toggleModal}
        status={status}
        estadoRequisicao={estadoRequisicao}
        situacaoBotao={situacaobtnFechar}
      />
      <SubDominio
        open={openModalSubDominio}
        toggleModalSubDominio={toggleModalSubDominio}
        dadosFormulario={dadosFormulario}
        setDadosFormulario={setDadosFormulario}
      />
      <GeraSenha
        open={openModalGeraSenha}
        toggleModalSenha={toggleModalGeraSenha}
        cnpj={tiraMascaraCNPJ(dadosFormulario.cnpj)}
        email={dadosFormulario.email}
        sistema={sistema}
      />
    </CardSystem>
  );
};
