import Modal from 'react-modal';
import { api } from '../../../Services/api';
import { CButton } from '../../Button/Button';
import { DivStyled, InputSubDominio } from './style';
import axios from 'axios';
import { toast } from 'react-toastify';

export const SubDominio = ({
  open,
  toggleModalSubDominio,
  dadosFormulario,
  setDadosFormulario,
}) => {
  async function validaDominio(subdominio) {
    let dominioValido = false;

    if (subdominio === '') {
      toast.error('Campo Subdominio é Obrigatorio!');
      return false;
    }

    await api
      .get(`pessoacliente/subdomain/${subdominio}`)
      .then((result) => {
        if (result.status === 200) {
          toast.error('Já existe um subdomínio cadastrado!');
          dominioValido = false;
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          dominioValido = true;
        } else {
          toast.error('Ocorreu um erro ao buscar o subdomínio!');
          dominioValido = false;
        }
      });

    return dominioValido;
  }

  async function submit() {
    const subdominio = document.getElementById('subdominio').value;
    if (await validaDominio(subdominio)) {
      await api
        .put(`pessoacliente/${dadosFormulario.id_cliente}`, {
          subdominio: subdominio,
        })
        .then(async () => {
          setDadosFormulario({ ...dadosFormulario, subdominio: subdominio });

          const cidade = await api.get(`cidade/${dadosFormulario.id_cidade}`);
          const estado = await api.get(`estado/${dadosFormulario.id_estado}`);

          const estabelecimento = {
            cnpj: dadosFormulario.cnpj,
            razao_social: dadosFormulario.razao_social,
            fantasia: dadosFormulario.fantasia,
            cep: dadosFormulario.cep,
            endereco_completo: dadosFormulario.endereco,
            endereco_nro: dadosFormulario.endereco_nro,
            complemento: dadosFormulario.complemento,
            bairro: dadosFormulario.bairro,
            cidade: cidade.data.descricao,
            estado: estado.data.uf,
            telefone: dadosFormulario.telefone,
            email: dadosFormulario.email,
            site: dadosFormulario.site,
            subdominio: subdominio,
          };

          const config = {
            headers: {
              cnpj: estabelecimento.cnpj,
              Authorization: localStorage.getItem('token'),
            },
          };

          axios
            .post(
              `${process.env.REACT_APP_BASE_URL_PEDIDO}/estabelecimento`,
              estabelecimento,
              config,
            )
            .then((response) => {
              if (response.status === 201) {
                toast.success('Dados salvos!');
                toggleModalSubDominio();
              } else {
                toast.error('Erro ao salvar dados!');
              }
            })
            .catch((erro) => {
              toast.error(erro);
            });
        })
        .catch(() => {
          toast.error('Erro ao salvar o subdominio!');
        });
    }
  }

  const handleChange = (event) => {
    let { name, value } = event.target;
    setDadosFormulario({
      ...dadosFormulario,
      [name]: value.replace(/[^a-z0-9]/g, ''),
    });
  };

  return (
    <Modal
      className="react-modal-SubDominio"
      overlayClassName="react-modal-overlay"
      isOpen={open}
    >
      <DivStyled>
        <h4>Preencha o subdomínio deste cliente para o Pedido Web:</h4>
        <fieldset>
          <legend>Subdomínio</legend>
          <InputSubDominio
            name="subdominio"
            value={dadosFormulario.subdominio}
            id="subdominio"
            maxLength={30}
            onChange={handleChange}
          />
        </fieldset>
        <CButton
          title={'Gravar'}
          colorButton={'var(--primary-color)'}
          // onClick={submit}
          onClickButton={submit}
        />
      </DivStyled>
    </Modal>
  );
};
