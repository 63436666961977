import { api } from '../../Services/api';
import EditIcon from '@material-ui/icons/Edit';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import {
  Cabecalho,
  DivBusca,
  DivConteudo,
  DivOpcoes,
  DivSituacao,
} from './style';
import { useHistory } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import { geraMascaraCNPJ } from '../../utils/cnpj';
import { getSituacaoSistema } from '../../utils/FormularioCliente/actionsFormularioCliente.js';
import correct from '../../assets/images/correct.png';
import block from '../../assets/images/block.png';

const columns = [
  { id: 'codigo', label: 'Código' },
  { id: 'cnpj', label: 'CNPJ' },
  { id: 'razao', label: 'Razão Social' },
  { id: 'revenda', label: 'Revenda' },
  { id: 'the_one', label: 'The One' },
  { id: 'phoenix', label: 'Phoenix' },
  { id: 'pedidos', label: 'Pedidos' },
  { id: 'opcoes', label: 'Opções' },
];

const useStyles = makeStyles({
  root: {
    width: '100%',
    margin: '0rem auto',
    gridRow: '2/4',
    border: 'none',
    boxShadow: 'none',
  },

  container: {
    maxHeight: '100%',
    border: 'none',
  },

  tHead: {
    background: '#D1D2D4',
    border: 'none',
    padding: '.5rem',
  },

  tRow: {
    lineHeight: '2.5rem',
    height: '2.5rem',
    maxHeight: '2.5rem',
  },

  tCell: {
    padding: '.25rem',
  },

  tCellDisabled: {
    padding: '.25rem',
    color: '#CCC',
  },

  tableCustom: {
    border: 'none',
  },
});

export const TabelaClientes = () => {
  // eslint-disable-next-line
  const [rows, setRows] = useState(0);
  const history = useHistory();

  const classes = useStyles();

  // eslint-disable-next-line
  const [page, setPage] = useState(0);

  // eslint-disable-next-line
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // eslint-disable-next-line
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // eslint-disable-next-line
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [clientes, setClientes] = useState([]);
  const [search, setSearch] = useState([]);

  useEffect(() => {
    api
      .get('pessoas/sistemas')
      .then((response) => {
        setClientes(response.data);
        setSearch(response.data);
        setRows(response.data.length);
      })
      .catch((error) => {});
  }, []);

  function getSituacao(situacao) {
    if (situacao === 1)
      return (
        <DivSituacao>
          <img src={correct} alt="ativo" className="active_svg" />
          <span> Ativo</span>
        </DivSituacao>
      );
    else
      return (
        <DivSituacao>
          <img src={block} alt="inativo" />
          <span> Inativo</span>
        </DivSituacao>
      );
  }

  const filtrarClientes = (value) => {
    const response = search.filter(
      (el) =>
        el.numero.includes(value.replace(/[^0-9,a-z,A-Z]/g, '')) ||
        el.nome.toLowerCase().includes(value.toLowerCase()),
    );
    setClientes(response);
  };

  const handleAlterarPesquisa = (e) => {
    filtrarClientes(e.target.value);
  };

  const verificaPessoaInativa = (pessoa) => {
    let classe;

    if (pessoa.cliente !== null) {
      if (pessoa.cliente.id_situacao_cadastro === 1) {
        classe = classes.tCell;
      } else {
        classe = classes.tCellDisabled;
      }
    } else {  
      if (pessoa.revenda !== null && pessoa.revenda.id_situacao_cadastro === 1) {
        classe = classes.tCell;
      } else {
        classe = classes.tCellDisabled;
      }
    }

    return classe;
  };

  return (
    <>
      <DivConteudo>
        <Cabecalho>
          <h1>
            <i className="fas fa-user-friends"></i> Clientes
          </h1>
          <DivOpcoes className="funcoes">
            <DivBusca className="sample seven">
              <input
                type="text"
                name="search"
                placeholder="Pesquisar "
                onChange={handleAlterarPesquisa}
              />
              {/* <button type="submit" className="btn btn-search">
                <i className="fa fa-search"></i>
              </button> */}
            </DivBusca>
            <button
              id="novo_cliente"
              onClick={() => {
                history.push('/novo-cliente');
              }}
            >
              <i className="fas fa-plus"></i> Novo Cliente
            </button>
          </DivOpcoes>
        </Cabecalho>

        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table
              stickyHeader
              aria-label="sticky table"
              className={classes.tableCustom}
            >
              <TableHead className={classes.tHead}>
                <TableRow className={classes.tRow}>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                      className={classes.tCell}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {clientes.map((column) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={column.id}
                      className={classes.tRow}
                    >
                      <TableCell
                        align={column.align}
                        className={verificaPessoaInativa(column)}
                      >
                        {column.id}
                      </TableCell>

                      <TableCell
                        align={column.align}
                        className={verificaPessoaInativa(column)}
                      >
                        {geraMascaraCNPJ(column.numero)}
                      </TableCell>

                      <TableCell
                        align={column.align}
                        className={verificaPessoaInativa(column)}
                      >
                        {column.nome}
                      </TableCell>

                      <TableCell
                        align={column.align}
                        className={verificaPessoaInativa(column)}
                      >
                        {column.cliente
                          ? column.cliente.revenda !== null
                            ? column.cliente.revenda.nome_fantasia
                            : ''
                          : column.revenda
                          ? ''
                          : ''}
                      </TableCell>

                      <TableCell
                        align={column.align}
                        className={verificaPessoaInativa(column)}
                      >
                        {/* The One */}
                        {column.revenda
                          ? getSituacao(2)
                          : column.cliente.sistemas[0]
                          ? getSituacaoSistema(column.cliente.sistemas, 2)
                            ? getSituacao(1)
                            : getSituacao(2)
                          : getSituacao(2)}
                      </TableCell>

                      <TableCell
                        align={column.align}
                        className={verificaPessoaInativa(column)}
                      >
                        {/* Phoenix */}
                        {column.revenda
                          ? getSituacao(2)
                          : column.cliente.sistemas[0]
                          ? getSituacaoSistema(column.cliente.sistemas, 3)
                            ? getSituacao(1)
                            : getSituacao(2)
                          : getSituacao(2)}
                      </TableCell>

                      <TableCell
                        align={column.align}
                        className={verificaPessoaInativa(column)}
                      >
                        {/* Pedidos Web */}
                        {column.revenda
                          ? getSituacao(2)
                          : column.cliente.sistemas[0]
                          ? getSituacaoSistema(column.cliente.sistemas, 1)
                            ? getSituacao(1)
                            : getSituacao(2)
                          : getSituacao(2)}
                      </TableCell>

                      <TableCell
                        align={column.align}
                        className={verificaPessoaInativa(column)}
                      >
                        <Link to={`/edita-cliente/${column.id}`}>
                          <IconButton aria-label="update">
                            <EditIcon />
                          </IconButton>
                        </Link>
                        {/* <IconButton aria-label="delete">
                          <DeleteIcon className="lixo" />
                        </IconButton> */}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>

          {/* <TablePagination
            rows={rows}        
            pageSize={5}
            rowsPerPageOptions={[10]}
            component="div"
            count={rows}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
        </Paper>
        <br />
      </DivConteudo>
    </>
  );
};
