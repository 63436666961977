import { CircularProgress } from '@material-ui/core';
import styled from 'styled-components';

export const ConteudoLoading = styled.div`
    padding: 0.7rem;
    text-align: center;
    img {
        width: 5.95rem;
    }

    p {
        font-weight: bold;
        color: var(--fontColor);
        margin-bottom: 1rem;
    }
`;

export const LoadingStatus = styled(CircularProgress)`
    .MuiCircularProgress-colorPrimary {
        color: #ff8a00;
    }
    .MuiCircularProgress-circleIndeterminate {
        stroke-dasharray: 10px, 200px;
    }
`;