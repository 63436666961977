export function tratarBlob(descricao) {
  if (descricao === null || descricao === undefined) {
    return;
  }
  const base64 = Buffer.from(descricao).toString("base64");
  return atob(base64);
}

export function zeroEsquerda(conteudo, zeros) {
  while (conteudo.length < zeros) {
    conteudo = '0' + conteudo;
  }
  return conteudo;
}