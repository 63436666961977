import { TabelaClientes } from "../../components/TabelaClientes";
import { Container, DivConteudo } from "./styles";

export const Painel = () => {
  return (
    <Container>
      <DivConteudo className="conteudo-dinamico">
        <TabelaClientes />
      </DivConteudo>
    </Container>
  );
};
