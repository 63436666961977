import { InputSenha, DivPassword } from "./styles"

export const ImputPassWord = ({handleChange, value, estadoSenha, estadoMostraSenha, mostraSenha, status, maxLength}) => {

  return (
    <fieldset>
      <legend>Senha</legend>
      <DivPassword>  
        <InputSenha 
          name={'input_senha'}
          value={value.input_senha}
          onChange={handleChange}
          type={mostraSenha ? 'password' : 'text'}
          maxLength={maxLength}
        />
        <button 
          onClick={estadoSenha}
        >
          {status[estadoMostraSenha]}
        </button>
      </DivPassword>
    </fieldset>
  )
}