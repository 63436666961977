import { ContainerHeader, ConteinerTitle, PageContainer } from './style';
import { useState, useEffect } from 'react';
// import Modal from "react-modal";
// import { Loading } from "../../Loading/Loading";
import { useParams } from 'react-router-dom';
// import { useHistory } from "react-router-dom";
import { PagSistemas } from './Sistemas';
import { DadosCliente } from './Formulario';
import { SituacaoCliente } from './SituacaoCliente';
// import { DadosFormularioProvider } from "../../hooks/useDadosFormulario";
// import axios from "axios";
// import { api } from "../../../Services/api";
import {
  carregaCliente /*, setEstadoCidade */,
} from '../../../utils/FormularioCliente/actionsFormularioCliente';
// import { Button } from "@material-ui/core";
import { FormHeader } from '../FormHeader';
// import { FunctionsTwoTone } from "@material-ui/icons";

export const FormularioNovoCliente = () => {
  // const history                               = useHistory();
  const cliente = useParams();
  const [dadosFormulario, setDadosFormulario] = useState({
    id_tipo_pessoa: '1',
  });

  // const [situacao, setSituacao]                 = useState(0);
  const [situacaoCliente, setSituacaoCliente] = useState(true);
  // const [modalIsOpen, setIsOpen]                = useState(false);
  const [visibleTab, setVisibleTab] = useState(0);
  const [renderComponents, setRenderComponents] = useState();

  const handleChange = (event) => {
    let { name, value } = event.target;

    let newValue = value;

    if (name === 'subdominio') {
      newValue = String(value).toLowerCase();
    }

    setDadosFormulario({
      ...dadosFormulario,
      [name]: newValue,
    });
  };

  useEffect(() => {
    const fncCarregaCliente = async () =>
      await carregaCliente(setSituacaoCliente, setDadosFormulario, cliente.id);

    fncCarregaCliente();
  }, [cliente.id]);

  // useEffect( async () => {
  //   await carregaCliente(setSituacaoCliente, setDadosFormulario, cliente.id);
  // }, [cliente.id])

  useEffect(() => {
    setRenderComponents(
      visibleTab ? (
        <PagSistemas
          statusConfig={1}
          dadosFormulario={dadosFormulario}
          setDadosFormulario={setDadosFormulario}
          handleChange={handleChange}
        />
      ) : (
        <DadosCliente
          statusConfig={1}
          dadosFormulario={dadosFormulario}
          setDadosFormulario={setDadosFormulario}
          handleChange={handleChange}
        />
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dadosFormulario]);

  useEffect(() => {
    setRenderComponents(
      visibleTab ? (
        <PagSistemas
          statusConfig={1}
          dadosFormulario={dadosFormulario}
          setDadosFormulario={setDadosFormulario}
          handleChange={handleChange}
        />
      ) : (
        <DadosCliente
          statusConfig={1}
          dadosFormulario={dadosFormulario}
          setDadosFormulario={setDadosFormulario}
          handleChange={handleChange}
        />
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleTab]);

  return (
    <>
      <ConteinerTitle>
        <FormHeader dadosFormulario={dadosFormulario} />
      </ConteinerTitle>
      <PageContainer>
        <ConteinerTitle>
          <ContainerHeader>
            <ul>
              <li
                id="tab_geral"
                onClick={() => setVisibleTab(0)}
                className={
                  visibleTab === 0 ? 'tab-title tab-title--active' : 'tab-title'
                }
              >
                Geral
              </li>
              <li
                id="tab_sistemas"
                onClick={
                  cliente.id === undefined
                    ? () => setVisibleTab(0)
                    : () => setVisibleTab(1)
                }
                className={
                  cliente.id === undefined
                    ? 'tab-title--block'
                    : visibleTab === 1
                    ? 'tab-title tab-title--active'
                    : 'tab-title'
                }
              >
                Sistemas
              </li>
            </ul>

            <SituacaoCliente
              check={situacaoCliente}
              SetSituacaoCliente={setSituacaoCliente}
              tipoCliente={dadosFormulario.id_tipo_pessoa}
              idCliente={dadosFormulario.id_cliente}
            />
          </ContainerHeader>
        </ConteinerTitle>

        {renderComponents}
      </PageContainer>
    </>
  );
};
