import { StyledSelectDefault } from './styles';

export const SelectRevenda = ({
  nome,
  options,
  setData,
  idHtml,
  selecionado,
}) => {
  return (
    <StyledSelectDefault>
      <div id={idHtml} className="select-label">
        <select
          id={`select-choice-revenda`}
          className="select"
          onChange={(e) => {
            setData(e.target.value);
          }}
        >
          <option value="" selected>
            {nome}
          </option>
          {options.map((option) => {
            if (selecionado === option.id)
              return (
                <option selected key={option.id} value={option.id}>
                  {option.nome_fantasia}
                </option>
              );
            else
              return (
                <option key={option.id} value={option.id}>
                  {option.nome_fantasia}
                </option>
              );
          })}
        </select>
      </div>
    </StyledSelectDefault>
  );
};
