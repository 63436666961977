import { createGlobalStyle } from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';

const GlobalStyle = createGlobalStyle`
  :root{
    --primary-color: #F36E21;
    --nav-color: #D1D2D4;
    --menu-color: #231F20;
    --greyDark:  #57585A;
    --btnCancelar: #57585A;
    /* --background-color: #F9F9F9; */
    --background-color: #f1f1f1;
    --greyLight: #f2f5fa;
    --greyLight: #00000029;
    --purpleDark: #252234;
    --Green: #139C45;
    --greyBtn: #57585A;
    --baseColor: #c3c3c363;
    --fontColor: #57585A;
    --fontColorWhite: #FFFFFF;
    --error: #EE1D23;
    --disabled: rgb(0 0 0 / 4%);
  }

  body{
    background-color: var(--background-color);
    font-family: Roboto, sans-serif;

    fieldset, legend {
      margin: 0;
      padding: 0;
      /* border: 0; */
      font-size: 100%;
      font: inherit;
      vertical-align: baseline;
    }
    
    button{
      cursor: pointer;
      border: none;
      outline: none;
      border-radius: 0.4rem;
      transition: filter 0.5s;

      :hover {
        filter: brightness(0.9);
      }

      :disabled {
        cursor: not-allowed;
        color: var(--fontColorWhite);
      }

      .MuiButton-label{
        cursor: pointer;
      }
    }

    select {
      outline: none;
    }

    span {
      cursor: default;
    }


    .fa-check-circle {
      color: green;
    }

    .fa-ban {
      color: red;
    }

    .active_svg {
      padding: 4.5px;
    }

    /* #Formulário */

    .top_zero {
      top: 0;
    }

    .top_two {
      top: 2;
    }

    .btnTop {
      position: fixed;
      margin: 0 1rem 1rem 0;
      border-radius: 2rem;
      bottom: 0;
      right: 0;
    }

    .botao_cancelar { 
      background: var(--btnCancelar);
      color: var(--fontColorWhite);
      :hover {
        background: var(--btnCancelar);
      }
    }

    .btn_salvar {
      background: var(--primary-color);
      color: var(--fontColorWhite);
      :hover {
        background: var(--primary-color);
      }
    }

    .btn_cria_sistema {
      background: var(--primary-color);
      color: var(--fontColorWhite);
      :hover {
        background: var(--primary-color);
      }
    }

    .btn_sistema_ativo {
      background: var(--Green);
      opacity: 70%;
      color: var(--fontColorWhite);
      :hover {
        background: var(--Green);
      }
    }

    .btn_black_sistema {
      background: var(--btnCancelar);
      color: var(--fontColorWhite);
      :hover {
        background: var(--btnCancelar);
      }  
    }
  }

  *{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
  }

  .react-modal-overlay {
    background: rgba(0, 0, 0, 0.5 );
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
  }
  .react-modal-content {
    width: 100%;
    max-width: 30rem;
    background: white;
    padding: 1.2rem;
    position: relative;
    border-radius: 0.25rem;
  }
  .react-modal-SubDominio {
    width: 100%;
    max-width: 56rem;
    background: white;
    padding: 2rem;
    position: relative;
    border-radius: 0.25rem;
  }
  .react-modal-Senha {
    width: 100%;
    max-width: 30rem;
    background: white;
    padding: 1.5rem;
    position: relative;
    border-radius: 0.25rem;
  }
  .react-modal-close {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    border: 0;
    background: transparent;
    transition: filter 0.2s;
    &:hover {
      filter: brightness(0.6);
    }
  }

  #btnClose_Modal{
    cursor: pointer;
    position: relative;
    float: right;
    color: var(--fontColor);
  }

@media (prefers-reduced-motion: no-preference) {
  html {
    scroll-behavior: smooth;
  }
}

:target {
  scroll-margin-top: .8em;
}

`;

export default GlobalStyle;
