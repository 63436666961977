import { FormControlLabel, Switch } from '@material-ui/core';
import { toast } from 'react-toastify';
import { api } from '../../../Services/api';

export const SituacaoCliente = ({
  check,
  SetSituacaoCliente,
  tipoCliente,
  idCliente,
}) => {
  let request =
    tipoCliente === 1
      ? `pessoacliente/${idCliente}/situacao`
      : `pessoarevenda/${idCliente}/situacao`;

  async function toggleCheckedAtivo() {
    try {
      const response = await api.patch(request);

      if (response.status === 200) {
        toast.success(response.data.message);
        SetSituacaoCliente(check === 1 ? 2 : 1);
      }
    } catch (err) {
      toast.error('Você só pode alterar o status após o cadastro!');
    }
  }

  function getSituacaoCliente() {
    return check === 1 ? 'Ativo' : 'Inativo';
  }

  return (
    <FormControlLabel
      control={
        <Switch
          color="primary"
          checked={check === 1 ? true : false}
          onChange={toggleCheckedAtivo}
        />
      }
      label={getSituacaoCliente()}
    />
  );
};
