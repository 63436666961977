import { ConteudoIcons } from "./styles"

export function Icons({img, statusConfig}) {

  const statusIcons = [
    <img src={window.location.origin + img} alt="Mostrar Senha"/>,
    <img src={window.location.origin + img} alt="Ocultar Senha"/>,
  ];
  
  return (
    <ConteudoIcons>
      {statusIcons[statusConfig]}  
    </ConteudoIcons>
  );
}