import styled from "styled-components";

export const InputSubDominio = styled.input`
  background: transparent;
  font-size:18px;
  display:block;
  width:100%;
  border: none;
  margin-left: 0.5rem;

  :disabled {
    background: var(--disabled);
    cursor: not-allowed;
  }  

  :focus { 
    outline:none; 
  }
`;

export const DivStyled = styled.div`
  align-items: center;
  justify-content: center;

  fieldset {
    padding: 0.2rem 0 0.6rem 0;
    margin-bottom: 2rem;
    border-radius: 0.6rem;
    :focus-within {
      
    }

    legend {
      margin-left: 1rem;
      font-size: 0.8rem;
      padding: 0 0.4rem 0 0.4rem;
    }
  }

  h4 {
    color: var(--fontColor);
    text-align: center;
    margin-bottom: 2.4rem;
  }
`;