import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 10% 80% 10%;

  &.conteudo-dinamico{
    grid-column: 1/2;
    grid-row: 1/3;
  }
`;

export const DivConteudo = styled.div`
  display: grid;
  /* grid-template-rows: 95% 5%; */
  &.footer {
    grid-row: 2/3;
  }
`;

export const DivTable = styled.div`
  padding: 0rem 1rem;
  max-height: 1rem;
  table { 
    width: 100%;
    border-spacing: 0 0.5rem;

    th{
      color: black;
      background: #efefef;
      font-weight: bold;
      padding: 1rem 2rem;
      text-align: left;
      line-height: 1rem;

      :first-child{
        border-radius: 0.6rem 0 0 0.6rem;
      }

      :last-child{
        border-radius: 0 0.6rem 0.6rem 0;
      }
    }

    tr:hover{
      background: var(--baseColor);
      transition: all 0.9s;
      td {
        border-radius: 0;
      } 
    }

    td {
      padding: 0rem 2rem;
      border: 0;
      color: var(--text-body);
      border-radius: 0.25rem;

      &:first-child {
        color: var(--text-title);
      }

      &.deposit {
        color: var(--green);
      }

      &.withdraw {
        color: var(--red);
      }

      :last-child{
        float: right;
      }

      &.csituacao_cliente{
        width: 15%;
      }

      .MuiIconButton-root{
        :hover{
          background: transparent;
        }    
      }
    }
  }
`;