import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { CButton } from '../../Button/Button';
import { DivButtonHeader, DivFormHeader, DivComponents } from './style';
import {
  atualizaCadastro,
  realizaCadastro,
} from '../../../utils/FormularioCliente/actionsFormularioCliente';

export const FormHeader = ({ dadosFormulario }) => {
  const history = useHistory();

  const [boxShadow, setboxShadow] = useState({ boxShadow: { main: 'none' } });
  const [marginTop, setmarginTop] = useState({
    marginTop: { main: '3.75rem' },
  });

  const toggleShadow = () => {
    if (window.pageYOffset === 0) {
      setboxShadow({ boxShadow: { main: 'none' } });
      setmarginTop({ marginTop: { main: '3.75rem' } });
    } else {
      setboxShadow({ boxShadow: { main: '0px 3px 20px rgb(0 0 0 / 20%)' } });
      setmarginTop({ marginTop: { main: '0' } });
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleShadow);
  }, []);

  return (
    <>
      {
        <DivFormHeader boxShadow={boxShadow.boxShadow}>
          <DivComponents marginTop={marginTop.marginTop}>
            <h2>Dados do Cliente</h2>
            <DivButtonHeader classe="div_button_header">
              <CButton
                title={'Cancelar'}
                colorButton={'var(--btnCancelar)'}
                onClickButton={() => {
                  history.push('/menu');
                }}
              />
              {dadosFormulario.id === undefined ? (
                <CButton
                  title={'Salvar'}
                  colorButton={'var(--primary-color)'}
                  onClickButton={() => {
                    realizaCadastro(dadosFormulario);
                  }}
                />
              ) : (
                <CButton
                  title={'Atualizar'}
                  colorButton={'var(--primary-color)'}
                  onClickButton={() => {
                    atualizaCadastro(dadosFormulario);
                  }}
                />
              )}
            </DivButtonHeader>
          </DivComponents>
        </DivFormHeader>
      }
    </>
  );
};
