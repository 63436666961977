import React from "react";
import InputMask from "react-input-mask";
import { zeroEsquerda } from "../../utils/utils";
import { StyledInput } from "./styles";

export const InputCEP = ({type, label, name, handleChange, disabled,value, error, id}) => {
 return( 
    <StyledInput>
      <div className="group" style={{width: "10rem"}}>      
        <InputMask
          mask="99.999-999"
          name="cep"
          id="cep"
          required 
          onChange={handleChange}
          disabled={disabled}
          className={error}
          value={value !== "" ? zeroEsquerda(value, 8) : ""}          
        />
        <span className="highlight"></span>
        <span className="bar"></span>
        <label id="label_cnpj">CEP *</label>
      </div>
    </StyledInput> 
  )
};
