import styled from 'styled-components';

export const StyledButton = styled.button`
  display: block;
  padding: 0.6rem 2.2rem;
  font-family: Roboto, sans-serif;
  font-size: 1rem;
  color: var(--fontColorWhite);
  background: ${(props) => props.colorBotton};
`;

export const TopButton = styled.button`
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 1rem;
  font-family: Roboto, sans-serif;
  color: var(--fontColorWhite);
  background: ${(props) => props.colorBotton};
`;
