import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { api } from "../../../Services/api";
import { CardSistema } from "./CardSistema/CardSistema";
import { Container, Sistemas } from "./style"

export const PagSistemas = ({dadosFormulario, setDadosFormulario}) => {

  const [cardSistema, setCardSistema] = useState([]);

  useEffect(() => {
    function buscaSistemas() {
      api.get(`clientesistema/${dadosFormulario.id_cliente}`)
      .then((res) => {
        setCardSistema(res.data)
      })
      .catch((error) => {
        toast.error(error);
      })
    };

    buscaSistemas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (  
    <Container>
      <Sistemas className="situacao_sistemas">
        {cardSistema.map((sistema) => {
          // setSituacao(sistema.id_situacao === 1 ? true : false);
          return (
            <CardSistema 
              sistema={sistema}
              dadosFormulario={dadosFormulario} 
              setDadosFormulario={setDadosFormulario}
            />
          )
        })}        
      </Sistemas>
    </Container>
  );
  
}
