import { CircularProgress, makeStyles } from "@material-ui/core";
import { ConteudoLoading } from "./styles";

export function Loading(props) {


	const Style = makeStyles((theme) => ({
		primary: {
		  color: '#F36E21',
		  animationDuration: '1500ms',
		  strokeLinecap: 'round',
		  strokeDasharray: '50px, 200px'
		}
	}));

	const classes = Style();

	const statusImg = [
		<CircularProgress disableShrink={true} thickness={4} size='5rem' className={classes.primary} />,
		<img src={window.location.origin + props.img} alt="Concluído com sucesso!"/>,
		<img src={window.location.origin + props.img} alt="Erro ao criar banco de dados!" />
	];

	return (
		<ConteudoLoading>
			<p>{props.texto}</p>
			{statusImg[props.statusConfig]}
		</ ConteudoLoading>

	);
}