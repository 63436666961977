import styled from 'styled-components';

export const Cabecalho = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 1rem 0 2rem;
`;

export const DivOpcoes = styled.div`
  &.funcoes {
    display: flex;
  }

  #novo_cliente {
    padding: 0.5rem 1.5rem;
    background: var(--primary-color);
    color: var(--fontColorWhite);
    width: 70%;
    height: 2.5rem;
  }
`;

export const DivBusca = styled.div`
  width: 100%;
  margin: 0rem 1rem;
  height: 2.5rem;

  &.seven {
    input {
      font-size: 1rem;
      border: 0.02rem solid var(--greyLight);
      padding: 0 0.5rem;
      width: 100%;
      height: 2.5rem;
      margin: 0rem auto;
      border-radius: 0.6rem;
      font-weight: 500;
      &:focus {
        width: 100%;
        border: 0.01rem solid var(--greyLight);
        outline: none;
        ~ button {
          &.btn-search {
            background: var(--primary-color);
            border: none;
            color: var(--grayLight);
          }
          &.btn-reset {
            animation: bounceRight 0.6s;
            animation-delay: 0.2s;
            animation-timing-function: cubic-bezier(0.3, 0.2, 1, 0.8);
            color: var(--grayLight);
            left: 17rem;
          }
        }
      }
    }

    button {
      &.btn-search {
        background: var(--primary-color);
        padding: 0 0.2rem;
        border-left: none;
        height: 2.5rem;
        width: 1.5rem;
        transition: all 0.3s ease-in;
        border-radius: 0 1rem 1rem 0;
      }
    }
  }
`;

export const DivConteudo = styled.div`
  margin: 8rem 4rem;
  padding: 1rem;
  background-color: #ffffff;
  border-radius: 0.7rem;

  box-shadow: 0px 3px 6px #00000029;
`;

export const DivSituacao = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
`;
