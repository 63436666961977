import axios from 'axios';

export const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

api.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');

    if (token) {
      config.headers.Authorization = token;  
    } else {
      delete api.defaults.headers.common.Authorization;
    }
    
    return config;
  },

  error => Promise.reject(error)
);

export default api;