import { toast } from 'react-toastify';
import { api } from '../../Services/api';
import { tiraMascaraCNPJ } from '../cnpj';
import { removeMaskTelefoneECep, removeMaskCNAE } from '../mask';
import { tratarBlob } from '../utils';
import fetchJsonp from 'fetch-jsonp';

export function alternaLayoutCliente() {
  document.getElementById('tab_sistemas').style.display = '';
  document.getElementById('select_form_revenda').style.display = '';
  document.getElementById('input_form_subdominio').style.display = '';
  document.getElementById('input_form_site').style.display = '';
}

export function alternaLayoutRevenda() {
  document.getElementById('tab_sistemas').style.display = 'none';
  document.getElementById('select_form_revenda').style.display = 'none';
  document.getElementById('input_form_subdominio').style.display = 'none';
  document.getElementById('input_form_site').style.display = 'none';
}

function validateEmail(email) {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}

function mensagemErro(id, situacao) {
  if (situacao) {
    document.getElementById(id).focus();
    document.getElementById(id).classList.add('campo_obrigatorio');
    return;
  }
  document.getElementById(id).classList.remove('campo_obrigatorio');
  return;
}

function validaCampos(dadosFormulario) {
  if (dadosFormulario.cnpj === '' || !dadosFormulario.cnpj) {
    toast.error('Campo CNPJ é Obrigatório!');
    mensagemErro('cnpj', true);
    return false;
  } else mensagemErro('cnpj', false);

  if (dadosFormulario.razao_social === '' || !dadosFormulario.razao_social) {
    toast.error('Campo Razão Social é Obrigatório!');
    mensagemErro('razao_social', true);
    return false;
  } else mensagemErro('razao_social', false);

  if (dadosFormulario.cep === '' || !dadosFormulario.cep) {
    toast.error('Campo CEP é Obrigatório!');
    mensagemErro('cep', true);
    return false;
  } else {
    mensagemErro('cep', false);
  }

  if (String(dadosFormulario.cep).length < 8) {
    toast.error('O CEP deve conter 8 digitos!');
    mensagemErro('cep', true);
    return false;
  } else {
    mensagemErro('cep', false);
  }

  if (dadosFormulario.endereco === '' || !dadosFormulario.endereco) {
    toast.error('Campo Endereço é Obrigatório!');
    mensagemErro('endereco', true);
    return false;
  } else mensagemErro('endereco', false);

  if (dadosFormulario.endereco_nro === '' || !dadosFormulario.endereco_nro) {
    toast.error('Campo Número é Obrigatório!');
    mensagemErro('endereco_nro', true);
    return false;
  } else mensagemErro('endereco_nro', false);

  if (dadosFormulario.bairro === '' || !dadosFormulario.bairro) {
    toast.error('Campo Bairro é Obrigatório!');
    mensagemErro('bairro', true);
    return false;
  } else mensagemErro('bairro', true);

  if (dadosFormulario.id_estado === '' || !dadosFormulario.id_estado) {
    toast.error('Campo Estado é Obrigatório!');
    return false;
  }

  if (dadosFormulario.id_cidade === '' || !dadosFormulario.id_cidade) {
    toast.error('Campo Cidade é Obrigatório!');
    return false;
  }

  if (dadosFormulario.telefone === '' || !dadosFormulario.telefone) {
    toast.error('Campo Telefone é Obrigatório!');
    mensagemErro('telefone', true);
    return false;
  } else mensagemErro('telefone', false);

  if (dadosFormulario.email === '' || !dadosFormulario.email) {
    toast.error('Campo E-mail é Obrigatório!');
    mensagemErro('email', true);
    return false;
  } else {
    if (!validateEmail(dadosFormulario.email)) {
      toast.error('E-mail Inválido!');
      mensagemErro('email', true);
      return false;
    } else mensagemErro('email', false);
  }
  return true;
}

export function setEstadoCidade(estado, cidade) {
  // document.getElementById("selectEstado").value = estado;
  document.getElementById('selectCidade').value = cidade;
}

export async function carregaCliente(
  setSituacaoCliente,
  setDadosFormulario,
  id,
) {
  await api
    .get(`pessoa/${id}`)
    .then((response) => {
      let pessoa = {
        id: response.data.id,
        cnpj: response.data.numero,
        razao_social: response.data.nome,
        id_tipo_pessoa: response.data.id_tipo_pessoa,
      };

      if (response.data.id_tipo_pessoa === 1) {
        alternaLayoutCliente();
        pessoa = {
          ...pessoa,
          id_cliente: response.data.cliente.id,
          fantasia: response.data.cliente.nome_fantasia,
          inscricao_estadual: response.data.cliente.inscricao_estadual,
          inscricao_municipal: response.data.cliente.inscricao_municipal,
          cep: response.data.cliente.cep,
          endereco: response.data.cliente.logradouro,
          endereco_nro: response.data.cliente.numero_endereco,
          complemento: response.data.cliente.complemento,
          bairro: response.data.cliente.bairro,
          id_cidade: response.data.cliente.id_cidade,
          id_estado: response.data.cliente.cidade.id_estado,
          telefone: response.data.cliente.telefone,
          cnae: response.data.cliente.cnae,
          responsavel: response.data.cliente.responsavel,
          email: response.data.cliente.email,
          site: response.data.cliente.site,
          subdominio:
            response.data.cliente.subdominio == null
              ? ''
              : response.data.cliente.subdominio,
          observacao: tratarBlob(response.data.cliente.observacao),
          id_pessoa_revenda:
            response.data.cliente.id_pessoa_revenda == null
              ? ''
              : response.data.cliente.id_pessoa_revenda,
          situacao: response.data.cliente.id_situacao_cadastro,
        };
      } else {
        alternaLayoutRevenda();
        pessoa = {
          ...pessoa,
          id_cliente: response.data.revenda.id,
          fantasia: response.data.revenda.nome_fantasia,
          inscricao_estadual: response.data.revenda.inscricao_estadual,
          inscricao_municipal: response.data.revenda.inscricao_municipal,
          cep: response.data.revenda.cep,
          endereco: response.data.revenda.logradouro,
          endereco_nro: response.data.revenda.numero_endereco,
          complemento: response.data.revenda.complemento,
          bairro: response.data.revenda.bairro,
          id_cidade: response.data.revenda.id_cidade,
          id_estado: response.data.revenda.cidade.id_estado,
          telefone: response.data.revenda.telefone,
          cnae: response.data.revenda.cnae,
          responsavel: response.data.revenda.responsavel,
          email: response.data.revenda.email,
          observacao: tratarBlob(response.data.revenda.observacao),
          situacao: response.data.revenda.id_situacao_cadastro,
        };
      }
      setSituacaoCliente(pessoa.situacao);
      setDadosFormulario(pessoa);
    })
    .catch((err) => {});
}

export const getSituacaoSistema = (arraySistemas, sistemaProcurado) => {
  let situacao = false;
  arraySistemas.forEach((sistema) => {
    if (sistema.sistema.id === sistemaProcurado && sistema.situacao.id === 1)
      situacao = true;
  });
  return situacao;
};

function verificaPessoaExiste(cnpj) {
  const resposta = api
    .get('/pessoa', { headers: { cnpj } })
    .then((response) => {
      console.log(response);
      if (response.status === 200) {
        return true;
      }
    })
    .catch(() => {
      return false;
    });

  return resposta;
}

export async function realizaCadastro(dadosFormulario) {
  if (!dadosFormulario.cnpj) {
    toast.error('É necessário infomar um CNPJ!');
    return;
  }

  const pessoaExiste = await verificaPessoaExiste(
    tiraMascaraCNPJ(dadosFormulario.cnpj),
  );

  if (pessoaExiste === true) {
    toast.error('Já existe um cadastro com o CNPJ informado!');
    return;
  }

  if (removeMaskTelefoneECep(dadosFormulario.cep).length < 8) {
    toast.error('CEP deve conter 8 digitos!');
    return;
  }

  if (
    (removeMaskTelefoneECep(dadosFormulario.telefone).substr(2, 1) === '9' &&
      removeMaskTelefoneECep(dadosFormulario.telefone).length < 11) ||
    removeMaskTelefoneECep(dadosFormulario.telefone).length < 10
  ) {
    toast.error('Telefone inválido!');
    return;
  }

  if (removeMaskTelefoneECep(dadosFormulario.telefone).length < 8) {
    toast.error('CEP deve conter 8 digitos!');
    return;
  }

  if (validaCampos(dadosFormulario)) {
    await fetchJsonp(
      `https://www.receitaws.com.br/v1/cnpj/${tiraMascaraCNPJ(
        dadosFormulario.cnpj,
      )}`,
    )
      .then((res) => {
        res
          .json()
          .then((json) => {
            if (json.status === 'ERROR') {
              toast.error('Erro ao consultar CNPJ');
              return false;
            }

            if (json.situacao !== 'ATIVA') {
              toast.error(
                'O CNPJ DO CLIENTE: ' +
                  json.nome +
                  ' TEM PENDÊNCIAS COM A RECEITA FEDERAL OU ESTÁ INATIVADO!',
              );
              return;
            }
          })
          .catch((err) => {
            toast.error(`Erro!', ${err.message}`);
            return;
          });
      })
      .catch((erro) => {
        toast.error(`Erro ao obter dados do CNPJ!`);
        return;
      });

    let pessoa = {
      numero: tiraMascaraCNPJ(dadosFormulario.cnpj),
      nome: dadosFormulario.razao_social,
      id_tipo_pessoa: document.getElementById('selectPessoa').value,
    };

    if (pessoa.id_tipo_pessoa === '1') {
      const id_pessoa_revenda = document.getElementById(
        'select-choice-revenda',
      ).value;

      pessoa = {
        ...pessoa,
        cliente: {
          nome_fantasia: dadosFormulario.fantasia,
          inscricao_estadual: dadosFormulario.inscricao_estadual,
          inscricao_municipal: dadosFormulario.inscricao_municipal,
          cep: removeMaskTelefoneECep(dadosFormulario.cep),
          logradouro: dadosFormulario.endereco,
          numero_endereco: dadosFormulario.endereco_nro,
          complemento: dadosFormulario.complemento,
          bairro: dadosFormulario.bairro,
          id_cidade: dadosFormulario.id_cidade,
          telefone:
            removeMaskTelefoneECep(dadosFormulario.telefone).substr(2, 1) ===
            '9'
              ? removeMaskTelefoneECep(dadosFormulario.telefone).substr(0, 11)
              : removeMaskTelefoneECep(dadosFormulario.telefone).substr(0, 10),
          cnae: dadosFormulario.cnae,
          responsavel: dadosFormulario.responsavel,
          email: dadosFormulario.email,
          site: dadosFormulario.site,
          subdominio:
            dadosFormulario.subdominio === ''
              ? null
              : dadosFormulario.subdominio,
          observacao:
            dadosFormulario.observacao === ''
              ? null
              : dadosFormulario.observacao,
          id_pessoa_revenda:
            id_pessoa_revenda === '' ? null : id_pessoa_revenda,
          id_situacao_cadastro: 1,
        },
      };
    } else {
      pessoa = {
        ...pessoa,
        revenda: {
          nome_fantasia: dadosFormulario.fantasia,
          inscricao_estadual: dadosFormulario.inscricao_estadual,
          inscricao_municipal: dadosFormulario.inscricao_municipal,
          cep: removeMaskTelefoneECep(dadosFormulario.cep),
          logradouro: dadosFormulario.endereco,
          numero_endereco: dadosFormulario.endereco_nro,
          complemento: dadosFormulario.complemento,
          bairro: dadosFormulario.bairro,
          id_cidade: dadosFormulario.id_cidade,
          telefone:
            removeMaskTelefoneECep(dadosFormulario.telefone).substr(2, 1) ===
            '9'
              ? removeMaskTelefoneECep(dadosFormulario.telefone).substr(0, 11)
              : removeMaskTelefoneECep(dadosFormulario.telefone).substr(0, 10),
          cnae: dadosFormulario.cnae,
          responsavel: dadosFormulario.responsavel,
          email: dadosFormulario.email,
          observacao:
            dadosFormulario.observacao === ''
              ? null
              : dadosFormulario.observacao,
          id_situacao_cadastro: 1,
        },
      };
    }
    await api
      .post('pessoa', { ...pessoa })
      .then((result) => {
        toast.success(`Cliente Cadastrado ${result.data.nome} com sucesso! `);
        window.location.href = `/edita-cliente/${result.data.id}`;
      })
      .catch((error) => {
        toast.error(
          `Erro ao cadastrar o novo Cliente \nErro: ${error.response.data.message}`,
        );
        return;
      });
  }
}

export async function atualizaCadastro(dadosFormulario) {
  let pessoa = {
    numero: tiraMascaraCNPJ(dadosFormulario.cnpj),
    nome: dadosFormulario.razao_social,
    id_tipo_pessoa: dadosFormulario.id_tipo_pessoa,
  };

  if (!dadosFormulario.cep) {
    toast.error('O campo CEP é obrigatório!');
    return;
  }

  if (!dadosFormulario.endereco) {
    toast.error('O campo LOGRADOURO é obrigatório!');
    return;
  }

  if (!dadosFormulario.razao_social) {
    toast.error('O campo RAZÃO SOCIAL é obrigatório!');
    return;
  }

  if (!dadosFormulario.bairro) {
    toast.error('O campo BAIRRO é obrigatório!');
    return;
  }

  if (!dadosFormulario.telefone) {
    toast.error('O campo TELEFONE é obrigatório!');
    return;
  }

  if (!dadosFormulario.email) {
    toast.error('O campo EMAIL é obrigatório!');
    return;
  }

  if (!dadosFormulario.endereco_nro) {
    toast.error('O campo Nº ENDEREÇO é obrigatório!');
    return;
  }

  if (removeMaskTelefoneECep(dadosFormulario.cep).length < 8) {
    toast.error('CEP deve conter 8 digitos!');
    return;
  }

  if (!validateEmail(dadosFormulario.email)) {
    toast.error('E-mail Inválido!');
    return;
  }

  if (dadosFormulario.id_tipo_pessoa === 1) {
    let id_pessoa_revenda;
    try {
      id_pessoa_revenda = document.getElementById(
        'select-choice-revenda',
      ).value;
    } catch {
      id_pessoa_revenda = null;
    }

    if (
      (removeMaskTelefoneECep(dadosFormulario.telefone).substr(2, 1) === '9' &&
        removeMaskTelefoneECep(dadosFormulario.telefone).length < 11) ||
      removeMaskTelefoneECep(dadosFormulario.telefone).length < 10
    ) {
      toast.error('Telefone inválido!');
      return;
    }

    pessoa = {
      ...pessoa,
      cliente: {
        nome_fantasia: dadosFormulario.fantasia,
        inscricao_estadual: dadosFormulario.inscricao_estadual,
        inscricao_municipal: dadosFormulario.inscricao_municipal,
        cep: removeMaskTelefoneECep(dadosFormulario.cep),
        logradouro: dadosFormulario.endereco,
        numero_endereco: dadosFormulario.endereco_nro,
        complemento: dadosFormulario.complemento,
        bairro: dadosFormulario.bairro,
        id_cidade: dadosFormulario.cidade,
        telefone:
          removeMaskTelefoneECep(dadosFormulario.telefone).substr(2, 1) === '9'
            ? removeMaskTelefoneECep(dadosFormulario.telefone).substr(0, 11)
            : removeMaskTelefoneECep(dadosFormulario.telefone).substr(0, 10),
        cnae: removeMaskCNAE(dadosFormulario.cnae),
        responsavel: dadosFormulario.responsavel,
        email: dadosFormulario.email,
        site: dadosFormulario.site,
        subdominio:
          dadosFormulario.subdominio === '' ? null : dadosFormulario.subdominio,
        observacao:
          dadosFormulario.observacao === '' ? null : dadosFormulario.observacao,
        id_pessoa_revenda: id_pessoa_revenda === '' ? null : id_pessoa_revenda,
      },
    };
  } else {
    pessoa = {
      ...pessoa,
      revenda: {
        nome_fantasia: dadosFormulario.fantasia,
        inscricao_estadual: dadosFormulario.inscricao_estadual,
        inscricao_municipal: dadosFormulario.inscricao_municipal,
        cep: removeMaskTelefoneECep(dadosFormulario.cep),
        logradouro: dadosFormulario.endereco,
        numero_endereco: dadosFormulario.endereco_nro,
        complemento: dadosFormulario.complemento,
        bairro: dadosFormulario.bairro,
        id_cidade: dadosFormulario.cidade,
        telefone:
          removeMaskTelefoneECep(dadosFormulario.telefone).substr(2, 1) === '9'
            ? removeMaskTelefoneECep(dadosFormulario.telefone).substr(0, 11)
            : removeMaskTelefoneECep(dadosFormulario.telefone).substr(0, 10),
        cnae: removeMaskCNAE(dadosFormulario.cnae),
        responsavel: dadosFormulario.responsavel,
        email: dadosFormulario.email,
        observacao:
          dadosFormulario.observacao === '' ? null : dadosFormulario.observacao,
      },
    };
  }

  await api
    .put(`pessoa/${dadosFormulario.id}`, { ...pessoa })
    .then((result) => {
      toast.success(`Cliente atualizado com sucesso!`);
      setTimeout(() => {
        window.location.href = `/menu`;
      }, 1500);
    })
    .catch((error) => {
      toast.error('Erro ao atualizar o cliente');
      return;
    });
}
