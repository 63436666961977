import React, { useEffect, useState } from 'react';
import { CButton } from './TopButton-props';

export const TopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 200) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
  }, []);

  return (
    <>
      {isVisible && (
        <CButton
          title={'▲'}
          colorButton={'var(--greyDark)'}
          classe={'btnTop'}
          onClickButton={scrollToTop}
        />
      )}
    </>
  );
};
