import styled from 'styled-components';

export const StyledInput = styled.div`
  .group {
    position: relative;
    margin-bottom: 1rem;
  }

  input {
    background: transparent;
    font-size: 18px;
    padding: 10px 10px 10px 5px;
    display: block;
    width: 100%;
    border: none;
    border-bottom: 1px solid #757575;
    border-radius: 0.25rem;

    :disabled {
      background: var(--disabled);
      cursor: not-allowed;
    }

    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .campo_obrigatorio {
    border-bottom: 2px solid red;
  }

  input:focus {
    border-color: var(--primary-color);
    outline: none;
  }

  /* LABEL ======================================= */
  label {
    color: #999;
    font-size: 18px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: 10px;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }

  /* active state */
  input:focus ~ label,
  input:valid ~ label {
    top: -20px;
    font-size: 14px;
    color: var(--primary-color);
  }

  /* BOTTOM BARS ================================= */
  .bar {
    position: relative;
    display: block;
    width: 100%;
  }

  .bar:before,
  .bar:after {
    content: '';
    height: 2px;
    width: 0;
    bottom: 1px;
    position: absolute;
    background: var(--primary-color);
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }

  .bar:before {
    left: 50%;
  }

  .bar:after {
    right: 50%;
  }

  /* active state */
  input:focus ~ .bar:before,
  input:focus ~ .bar:after {
    width: 50%;
  }

  /* HIGHLIGHTER ================================== */
  .highlight {
    position: absolute;
    height: 60%;
    width: 100px;
    top: 25%;
    left: 0;
    pointer-events: none;
    opacity: 0.5;
  }

  /* active state */
  input:focus ~ .highlight {
    -webkit-animation: inputHighlighter 0.3s ease;
    -moz-animation: inputHighlighter 0.3s ease;
    animation: inputHighlighter 0.3s ease;
  }
`;

export const StyledSelect = styled.div`
  color: black;
  position: relative;

  .select-label {
    background: transparent;
    font-size: 18px;
    display: block;
    width: 100%;
    border: none;
    border-bottom: 1px solid #757575;
    border-radius: 0.25rem;
    /* margin:  20px 10px 10px 5px; */
    padding-bottom: 0.6rem;

    transition: 0.1s;

    &:focus-within {
      border-color: var(--primary-color);
      border-width: 3px;
    }

    fieldset {
      border: none;
      font-weight: 500;
      border-radius: 0.25rem;
    }
  }

  .select {
    padding-top: 0.5rem;
    border: none;
    width: 100%;
    font-size: 18px;
    display: block;
    width: 100%;
    border-radius: 0.25rem;
  }
`;

export const StyledSelectNoMargin = styled.div`
  color: black;
  position: relative;

  .select-label {
    font-size: 18px;
    display: block;
    width: 100%;
    border: none;
    border-bottom: 1px solid #757575;
    border-radius: 0.25rem;
    /* margin:  20px 10px 10px 5px; */
    padding-bottom: 0.6rem;

    fieldset {
      border: none;
      font-weight: 500;
      border-radius: 0.25rem;
    }
  }
  .select {
    padding-top: 0.5rem;
    border: none;
    width: 100%;
    font-size: 18px;
    display: block;
    width: 100%;
    border-radius: 0.25rem;
  }
`;

export const StyledSelectDefault = styled.div`
  color: black;
  position: relative;

  .select-label {
    background: transparent;
    font-size: 18px;
    display: block;
    width: 100%;
    border: none;
    border-bottom: 1px solid #757575;
    border-radius: 0.25rem;
    /* margin:  20px 10px 10px 5px; */
    padding-bottom: 0.8rem;

    transition: 0.1s;

    &:focus-within {
      border-color: var(--primary-color);
      border-width: 3px;
    }

    fieldset {
      border: none;
      font-weight: 500;
      border-radius: 0.25rem;
    }
  }
  .select {
    padding-top: 0.5rem;
    border: none;
    width: 100%;
    font-size: 18px;
    display: block;
    width: 100%;
    border-radius: 0.25rem;
  }
`;

export const StyledSelectDinamic = styled.div`
  color: var(--fontColorWhite);
  position: relative;

  .select-label {
    background: transparent;
    font-size: 18px;
    display: block;
    width: 100%;
    border: none;
    border-bottom: 1px solid #757575;
    border-radius: 0.25rem;
    /* margin:  20px 10px 10px 5px; */
    padding: 10px 10px 10px 5px;

    transition: 0.1s;

    &:focus-within {
      border-color: var(--primary-color);
      border-width: 3px;
    }
  }
  .select {
    border: none;
    width: 100%;
    font-size: 18px;
    display: block;
    width: 100%;
    border-radius: 0.25rem;
  }
`;

export const StyledTextArea = styled.div`
  textarea {
    outline: none;
    width: 100%;
    resize: none;
    :focus {
      border-color: var(--primary-color);
    }
  }
`;

export const InputSenha = styled.input`
  background: transparent;
  font-size: 1rem;
  display: block;
  width: 100%;
  border: none;
  padding: 0rem 0.6rem 0.3rem;

  :disabled {
    background: var(--disabled);
    cursor: not-allowed;
  }

  :focus {
    outline: none;
  }
`;

export const DivPassword = styled.div`
  display: flex;

  button {
    background: transparent;
  }
`;
