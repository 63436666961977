import styled from "styled-components";

export const DivFormHeader = styled.div`
  position: fixed;
  background: var(--background-color);
  z-index: 1;
  top: 4rem;
  left: 0;
  border: 1px solid var(--background-color); 
  box-shadow:  ${props => props.boxShadow.main};
  transition: 0.5s;
  min-width: 100vw;  
`;

DivFormHeader.defaultProps = {
  boxShadow: {
    main: "0px 3px 20px rgb(0 0 0 / 20%)" 
  }
};

export const DivButtonHeader = styled.div`
  display: flex;
  button {
    margin: 0 0.5rem;
    min-width: 10rem;
  }
`;

export const DivComponents = styled.div`
  max-width: 75rem;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 6fr 2fr;
  padding: 1rem 0; 
  margin-top:  ${props => props.marginTop.main};
  transition: 0.3s ease-in;
`;

DivComponents.defaultProps = {
  marginTop: {
    main: '3.75rem'
  }
};