import { TopButton } from "./style";

export const CButton = ({title, colorButton, onClickButton, classe}) => {
  return (
    <TopButton 
      colorBotton={colorButton}
      onClick={() => onClickButton()}
      className={classe !== '' ? classe : ''}      
    >
      {title}
    </TopButton>
  );
}