import styled from "styled-components";

export const BtnFechar = styled.button`
  background: transparent;
  cursor: pointer;
  padding: 0.5rem 1rem;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 0.4rem;
  :hover {
    background-color: transparent;
  }
`;