import { StyledButton } from "./style";

export const CButton = ({title, colorButton, onClickButton, classe}) => {
  return (
    <StyledButton 
      colorBotton={colorButton}
      onClick={() => onClickButton()}
      className={classe !== '' ? classe : ''}      
    >
      {title}
    </StyledButton>
  );
}