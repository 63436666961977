import { StyledInput } from "./styles";

export const InputDominio = ({ type, label, name, handleChange, disabled, error, dadosFormulario, erroSubdominio, setErroSubdominio, value }) => {

  function getSituacao() {
    if (value === undefined || value === null || value === '') {
      return true;
    }
    return false;
  }

  return (
    <StyledInput>
      <div className="group" id="input_form_subdominio">
        <input
          type={type}
          required
          name={name}
          onChange={handleChange}
          disabled={disabled}
          // onBlur={validaDominio}
          className={error}
        // value={value}
        />
        <span className="highlight"></span>
        <span className="bar"></span>
        <label>
          {
            getSituacao() ? 'Subdomínio não cadastrado' : `Subdomínio: ${value}`
          }
        </label>
      </div>
    </StyledInput>
  );
}