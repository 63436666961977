import styled from "styled-components";

export const ConteudoIcons = styled.div`
    padding: 0.3rem 0.7rem 0.6rem 0;
    text-align: center;
    float: right;
    position: relative;

    img {
        width: 1rem; 
    }
`;