import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import GlobalStyle from "./styles/GlobalStyles";
import Routes from './components/routes/route';
import { ToastContainer, Flip } from 'react-toastify';
import PainelLogin  from './pages/login';
import api from './Services/api';

setInterval(async () => {
  const refresh_token = localStorage.getItem('refresh_token');

  await api.post('autenticacao/refresh', {
    refresh_token
  })
  .then(response => {
    localStorage.setItem('refresh_token', response.data.refresh_token);
    localStorage.setItem('token', response.data.token);
  })
}, 900000);

function App() {  
  return (
    <>
      <GlobalStyle />
      <ToastContainer autoClose={3000} theme="colored" transition={Flip} />
      <Router> 
        <Switch>
          <Route exact path="/" component={PainelLogin} />
          <Routes />
        </Switch>
      </Router>
    </>
  );
}

export default App;
