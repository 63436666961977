import styled from 'styled-components';

export const NavBar = styled.div`
  padding: 0.7rem 0rem;
  margin-bottom: 0.5rem;
  background: var(--menu-color);
  height: 4rem;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3,1fr);
  position: fixed;
  z-index: 99;
  top: 0;
  img {
    grid-column: 2/3;
    margin: 0 auto;
    width: 16rem;
    cursor: pointer;
  }
`;

export const Logout = styled.div`
  grid-column: 3/4;
  display: flex;
  justify-content: flex-end;
  margin-right: 1rem;
  button{
    font-size: 1.3rem;
    padding: 0 1rem;
    background: transparent;
    cursor: pointer;
    color: var(--fontColorWhite);
    :hover {
      background: #3D3B3B;
    }
  } 
`
