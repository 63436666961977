import React from "react";
import { StyledInput } from "./styles";
import InputMask from "react-input-mask";

export const Input = ({type, label, name, handleChange, disabled, value, error, id, mask, idHtml, maxLength}) => {
  return (
    <StyledInput  id={idHtml}>
      <div className="group">  
        <InputMask 
          mask={mask}
          maskChar={""}
          type={type} 
          required 
          id={id}
          name={name} 
          value={value}
          disabled={disabled}
          className={error}
          onChange={handleChange}
          maxLength={maxLength}                   
        />    
        <span className="highlight"></span>
        <span className="bar"></span>
        <label>{label}</label>
      </div>
    </StyledInput> 
  );
}