import styled from 'styled-components';

export const DivGrid = styled.div`
  display: grid;
  grid-template-columns: 70% 25%;
  grid-gap: 5%;
  margin-bottom: 1.2rem;
`;

export const DivStyled = styled.div`
  align-items: center;
  justify-content: center;

  fieldset {
    margin-bottom: 0.6rem;
    border-radius: 0.6rem;

    legend {
      margin-left: 1rem;
      font-size: 0.8rem;
      padding: 0 0.4rem;
      font-family: Roboto, sans-serif;
    }
  }

  h4 {
    color: var(--fontColor);
    text-align: center;
    margin: 0 0 2rem 0;
  }
`;

export const ButtonGerar = styled.button`
  display: block;
  margin: auto;
  padding: 0.6rem 2rem;
  font-family: Roboto, sans-serif;
  font-size: 1rem;
  color: var(--fontColorWhite);
  background: var(--btnCancelar);
`;
