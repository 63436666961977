import axios from 'axios';
import { useState } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { CButton } from '../../Button/Button';
import { Icons } from '../../Input/icons/iconsImput';
import { ImputPassWord } from '../../Input/inputPassword';
import { BtnFechar } from '../UtilsModal/btnFechar';
import { DivStyled, DivGrid, ButtonGerar } from './style';
import olhoAberto from '../../../assets/images/olhoaberto.svg';
import olhoFechado from '../../../assets/images/olhofechado.svg';

export const GeraSenha = ({ open, toggleModalSenha, cnpj, email, sistema }) => {
  const [mostraSenha, setMostraSenha] = useState(true);

  const [estadoMostraSenha, setEstadoMostraSenha] = useState(0);
  const [status /*, setStatus */] = useState([
    <Icons img={olhoAberto} statusConfig={0} />,
    <Icons img={olhoFechado} statusConfig={1} />,
  ]);

  function estadoSenha() {
    setMostraSenha(!mostraSenha);
    setEstadoMostraSenha(mostraSenha ? 0 : 1);
  }

  const [geraSenha, setGeraSenha] = useState({});

  function generatePassword() {
    var length = 8,
      charset =
        'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%&*()',
      retVal = '';
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    setGeraSenha({ input_senha: retVal });
  }

  const handleChange = (event) => {
    let { name, value } = event.target;
    setGeraSenha({
      [name]: value.replace(/[^a-zA-Z0-9!@#$%&*()]/g, ''),
    });
  };

  async function salvaSenha() {
    const config = {
      headers: {
        cnpj: cnpj,
        Authorization: localStorage.getItem('token'),
      },
    };

    if (geraSenha.input_senha && geraSenha.input_senha.length >= 6) {
      switch (sistema.descricao_sistema) {
        case 'Pedido Web':
          axios
            .patch(
              `${sistema.url_servidor}/v1/api/estabelecimento/adm/senha`,
              {
                nova_senha: geraSenha.input_senha,
                email,
              },
              config,
            )
            .then((response) => {
              if (response.status === 200) {
                toast.success('Senha salva! E-mail enviado com sucesso!');
                toggleModalSenha();
              } else {
                toast.error('Erro ao salvar! Tente novamente.');
              }
            })
            .catch(() => {
              toast.error('Erro ao salvar! Tente novamente.');
            });
          break;
        case 'Phoenix':
          const usuario = {
            cnpj: config.headers.cnpj,
            senha: geraSenha.input_senha,
            email,
          };

          await axios
            .post(`${sistema.url_servidor}/v1/api/usuario/adm`, usuario, config)
            .then((response) => {
              if (response.status === 200) {
                toast.success('Senha salva! E-mail enviado com sucesso!');
                toggleModalSenha();
              } else {
                toast.error('Erro ao salvar! Tente novamente.');
              }
            })
            .catch(() => {
              toast.error('Erro ao salvar! Tente novamente.');
            });
          break;
        default:
          toast.warn('Função em desenvolvimento!');
      }
    } else {
      toast.warn('Senha inválida! Tamanho mínimo de senha: 6 caracteres.');
    }
  }

  return (
    <Modal
      className="react-modal-Senha"
      overlayClassName="react-modal-overlay"
      isOpen={open}
    >
      <DivStyled>
        <h4> Digite uma senha ou gere uma aleatória.</h4>
        <DivGrid>
          <ImputPassWord
            value={geraSenha}
            estadoSenha={estadoSenha}
            estadoMostraSenha={estadoMostraSenha}
            mostraSenha={mostraSenha}
            status={status}
            handleChange={handleChange}
            maxLength={30}
          />
          <ButtonGerar onClick={generatePassword}>Gerar</ButtonGerar>
        </DivGrid>
      </DivStyled>
      <CButton
        onClickButton={salvaSenha}
        title={'Gravar'}
        colorButton={'var(--primary-color)'}
      />
      <BtnFechar onClick={toggleModalSenha}>X</BtnFechar>
    </Modal>
  );
};
