import { useHistory } from 'react-router-dom';
import logo from '../../assets/images/dobes_one.png';
import { NavBar, Logout } from './styles';

export function TopBar() {
  const history = useHistory();

  function logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('login');
    localStorage.removeItem('senha');
    history.push('/');
  }

  return (
    <NavBar>
      <img
        src={logo}
        alt="Logo"
        onClick={() => {
          history.push('/menu');
        }}
      />
      <Logout>
        <button onClick={logout}>Logout</button>
      </Logout>
    </NavBar>
  );
}
