import { StyledSelectDinamic } from "./styles";

export const SelectEstado = ({id, nome, options, setData, selecionado}) => {
  return (
    <StyledSelectDinamic>
      <div className="select-label">
        {/* <label htmlFor="">{titulo}</label> */}
        <select id="selectEstado" className="select" onChange={(e) => { setData(e.target.value) } } >
          <option value="0" selected={true}>{nome}</option>
          { 
            options.map(option => {
              if (selecionado === option.id)  
                return  <option selected key={option.id} value={option.id}>{option.descricao}</option> 
              else
                return  <option key={option.id} value={option.id}>{option.descricao}</option> 
            })
          } 
        </select>
      </div>
    </StyledSelectDinamic>
  );
}