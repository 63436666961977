import { StyledTextArea } from './styles';

export const TextAreaInput = ({ name, handleChange, value }) => {
  return (
    <StyledTextArea>
      <textarea
        name={name}
        id=""
        rows="10"
        onChange={handleChange}
        value={value}
        placeholder="Escreva aqui observações sobre o cliente"
      ></textarea>
    </StyledTextArea>
  );
};
