import { Link, useHistory } from 'react-router-dom';

import {
  Container,
  FormContent,
  FormLogin,
  Lembrarme,
  SuportDiv,
} from './styles';
import logo from '../../assets/images/dobes_one.png';
import olhoAberto from '../../assets/images/olhoaberto.svg';
import olhoFechado from '../../assets/images/olhofechado.svg';
import React, { useState, useEffect } from 'react';
import { api } from '../../Services/api';
import { toast } from 'react-toastify';

export function Login() {
  const history = useHistory();

  const [login, setLogin] = useState('');
  const [senha, setSenha] = useState('');
  const [lembrarme, setLembrarme] = useState(false);
  const [mostrarSenha, setMostrarSenha] = useState(false);

  useEffect(() => {
    if (
      localStorage.getItem('login') !== null &&
      localStorage.getItem('senha') !== null
    ) {
      api
        .post('/autenticacao', {
          login: localStorage.getItem('login'),
          senha: localStorage.getItem('senha'),
        })
        .then((res) => {
          if (res.status === 201) {
            localStorage.setItem('token', res.data.token);
            localStorage.setItem('refresh_token', res.data.refresh_token);
            history.push('/menu');
          }
        })
        .catch((err) => {
          toast.error('Erro ao efetuar login automático!');
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function tryLogin(event) {
    event.preventDefault();

    await api
      .post('autenticacao', { login, senha })
      .then((res) => {
        if (res.status === 201) {
          if (lembrarme === true) {
            localStorage.setItem('login', login);
            localStorage.setItem('senha', senha);
          } else {
            localStorage.removeItem('login');
            localStorage.removeItem('senha');
          }

          localStorage.setItem('token', res.data.token);
          localStorage.setItem('refresh_token', res.data.refresh_token);
          history.push('/menu');
        }
      })
      .catch((err) => {
        toast.error('Dados inválidos!');
      });
  }

  return (
    <Container>
      <FormLogin>
        <div className="cabecalho">
          <img src={logo} alt="" />
          <div className="textAling">
            <h4>Seja bem-vindo!</h4>
            <h6>Preencha os dados de login e senha para acessar o sistema.</h6>
          </div>
        </div>
        <div className="form">
          <FormContent>
            <input
              type="text"
              placeholder="Login"
              value={login}
              onChange={(event) => setLogin(event.target.value)}
            />
          </FormContent>
          <FormContent>
            <input
              type={mostrarSenha ? 'text' : 'password'}
              placeholder="Senha"
              onChange={(event) => setSenha(event.target.value)}
            />
            <img
              src={mostrarSenha ? olhoFechado : olhoAberto}
              alt="Mostrar senha"
              onClick={() => setMostrarSenha(!mostrarSenha)}
            />
          </FormContent>
          <SuportDiv>
            <Lembrarme>
              <input
                type="checkbox"
                id="scales"
                name="scales"
                checked={lembrarme}
                onClick={() => setLembrarme(!lembrarme)}
              />
              <label for="scales">Lembrar-me</label>
            </Lembrarme>
          </SuportDiv>
          <button
            type="submit"
            title="Entrar"
            onClick={(event) => tryLogin(event)}
          >
            Acessar
          </button>
        </div>
        <div className="footer">
          <h6>
            @2021 Dobes One | Todos os direitos reservados. |{' '}
            <Link href={''}> Política de Privacidade</Link>
          </h6>
        </div>
      </FormLogin>
    </Container>
  );
}
