import styled from 'styled-components';

export const PageContainer = styled.div`
  margin: 12.5rem auto;
`;

export const Container = styled.div`
  margin: 1rem auto;
  max-width: 75rem;
  padding: 0.5rem 2rem 2rem 2rem;
  border-radius: 1rem;
  box-sizing: border-box;
  background: white;
  box-shadow: 0px 13px 16px rgb(0 0 0 / 20%);

  .container_header {
    grid-row: 1/2;
    display: grid;

    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;

    .title {
      grid-column: 1/2;
      grid-row: 1/2;
    }

    .cnpj {
      grid-column: 1/2;
      grid-row: 1/2;
    }

    .situacao_sistemas {
      grid-column: 2/3;
      grid-row: 1/3;
    }
  }

  .form_cadastro {
    grid-row: 2/3;
    height: auto;
  }

  .container_footer {
    grid-row: 3/4;
    height: 100%;
  }
`;

export const ConteinerTitle = styled.div`
  margin: auto;
  max-width: 75rem;
  background: transparent;

  .tabs-titles {
    list-style: none;
    padding: 0px;
    margin: 0;
  }

  .tab-title {
    font-weight: bold;
    text-align: center;
    display: inline-block;
    padding: 10px;
    color: #c7c6c2;
    cursor: pointer;
    width: 10rem;
    font-size: 1.5rem;
  }

  .tab-title--active {
    background-color: transparent;
    border-bottom: 0.4rem solid var(--primary-color);
    color: #00070a;
    font-size: 1.5rem;
  }

  .tab-title--block {
    font-weight: bold;
    text-align: center;
    display: inline-block;
    padding: 10px;
    color: #c7c6c2;
    cursor: pointer;
    width: 10rem;
    cursor: not-allowed;
    font-size: 1.5rem;
  }
`;

export const ConteinerTitleHeader = styled.div`
  margin: auto;
  max-width: 75rem;
  background: transparent;
`;

export const ContainerHeader = styled.div`
  margin: 4.5rem 0 1rem 0;
  display: flex;
  justify-content: space-between;
`;

export const ContainerCNPJ = styled.div``;

export const FormCadastro = styled.div`
  grid-template-rows: auto auto;

  .conteudo_form {
    grid-row: 1/2;
    height: auto;
  }

  .conteudo_footer {
    grid-row: 2/3;
    display: flex;
    justify-content: space-around;
  }
`;

export const GridTwo = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 1rem 0;
  gap: 1rem;
`;

export const GridFour = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin: 1rem 0;
  gap: 1rem;
  column-width: auto;

  button {
    margin: auto;
    width: 60%;
    background: var(--Green);
    color: var(--fontColorWhite);
    transition: filter 0.2s;
    margin-bottom: 1rem;
    text-transform: none;
  }

  button:hover {
    background: var(--Green);
  }
`;

export const GridThree = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 1rem 0;
  gap: 1rem;

  button {
    margin: auto;
    width: 100%;
    background: var(--Green);
    color: var(--fontColorWhite);
    transition: filter 0.2s;
    margin-bottom: 1rem;
    text-transform: none;
  }
  button:hover {
    background: var(--Green);
  }
`;

export const FormHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  border-bottom: 0.2rem solid var(--greyLight);
  padding: 0.5rem;

  h4 {
    cursor: pointer;
  }
`;

export const FormCadastroBodyDados = styled.div`
  margin: auto;
  display: block;
`;

export const FormCadastroBodyEndereco = styled.div`
  margin: auto;
  display: none;
`;

export const FormCadastroBodyObservacao = styled.div`
  margin: auto;
  display: none;
`;

export const FormCadastroFooter = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: space-around;
`;

export const Sistemas = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, 1fr);

  gap: 0.5rem;

  button {
    width: 20rem;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 2.5rem 0;
  height: 100%;
`;

export const CardSystem = styled.div`
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(3, 1fr);
  grid-auto-columns: minmax(auto, 18rem);
  gap: 0rem 1rem;
  margin: 1rem;

  h3 {
    grid-column: 1/5;
  }

  .eteste {
    color: var(--fontColorWhite);
    background-color: Red;
  }

  .ativo {
    grid-column: 1/2;
    grid-row: 2/3;
    min-width: 1.4rem;
  }
  .gerabanco {
    grid-column: 2/3;
    grid-row: 2/3;
  }
  .gerasenha {
    grid-column: 3/4;
    grid-row: 2/3;
  }
`;

export const GridFive = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 10rem 10rem 42rem 6rem);
  margin: 1rem 0;
  gap: 1rem;

  button {
    margin: auto;
    width: 100%;
    background: var(--Green);
    color: var(--fontColorWhite);
    transition: filter 0.2s;
    margin-bottom: 1rem;
    text-transform: none;
  }

  button:hover {
    background: var(--Green);
  }
`;
