import React from 'react';
import { Login } from '../../components/LoginAdmin';

const PainelLogin = (props) => {

  return(
    <Login />
  );
};

export default PainelLogin;