import { FormHeader } from './style';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

export const ContainerFormHeader = ({ titulo, displayBtn, setDisplay }) => {
  function toggleDisplayed() {
    setDisplay(!displayBtn);
  }
  return (
    <FormHeader>
      <h3>{titulo}</h3>
      <h4 onClick={toggleDisplayed}>
        {displayBtn ? <FiChevronUp size={18} /> : <FiChevronDown size={18} />}
      </h4>
    </FormHeader>
  );
};
