import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { useHistory } from 'react-router';
import { NewClient } from "../../pages/new-client";
import { Painel } from "../../pages/painel";
import { TopBar } from '../NavBar/index';


const Routes = () => {
  const history = useHistory();

  const token = localStorage.getItem('token');

  if(!token || token == null ) {
    history.push('/');
  };

  return (
    <>
      <TopBar />  
      <Switch>
        {/* Implementar a autenticação da Página */}
        <Route exact path='/menu' component={Painel} />
        <Route exact path="/novo-cliente" component={NewClient} />
        <Route exact path="/edita-cliente/:id?" component={NewClient} />
        <Redirect to="/" />
        {/* Não encontrado */}
        <Route> <h1>404</h1> </Route>
        { token == null ? <Redirect to='/' /> : ''
        }   
      </Switch>
    </>
  );
};

export default Routes;
