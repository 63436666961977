import Modal from "react-modal";
import { BtnFechar } from "../UtilsModal/btnFechar"

export const CriaBancoModal = ({open, toggleModal, status, estadoRequisicao, situacaoBotao}) => {

  return (
    <Modal
      className="react-modal-content"
      overlayClassName="react-modal-overlay"
      isOpen={open}
    >
      <BtnFechar 
        onClick={toggleModal} 
        style={situacaoBotao ? {display: 'none'} : {display: 'block'}}>
      X
      </BtnFechar>  
      {status[estadoRequisicao]}
    </Modal>
  );
}