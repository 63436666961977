import { StyledSelect } from './styles';

export const SelectTipoPessoa = ({
  id,
  nome,
  options,
  setData,
  selecionado,
}) => {
  return (
    <StyledSelect>
      <div className="select-label">
        <fieldset>
          <legend>
            <label>{nome}</label>
          </legend>
          <select
            id="selectPessoa"
            className="select"
            onChange={(e) => {
              setData(e.target.value);
            }}
          >
            {options.map((option) => {
              if (selecionado === option.id)
                return (
                  <option selected key={option.id} value={option.id}>
                    {option.descricao}
                  </option>
                );
              else
                return (
                  <option key={option.id} value={option.id}>
                    {option.descricao}
                  </option>
                );
            })}
          </select>
        </fieldset>
      </div>
    </StyledSelect>
  );
};
