import React from "react";
import InputMask from "react-input-mask";
import { StyledInput } from "./styles";

export const InputCNPJ = ({ error, cnpj, handleChange, disabled }) => {
 return( 
    <StyledInput>
      <div className="group">      
        <InputMask
          mask="99.999.999/9999-99"
          maskChar="_"
          required
          id="cnpj"
          name="cnpj"                         
          value={cnpj}
          className={error}
          onChange={handleChange}
          disabled={disabled ? 'disabled' : ''}
        />
        <span className="highlight"></span>
        <span className="bar"></span>
        {
          disabled 
            ? ''
            : <label id="label_cnpj">CNPJ *</label>
        }
      </div>
    </StyledInput> 
  )
};
