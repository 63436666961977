import { useState } from 'react';
import {
  Container,
  FormCadastroBodyDados,
  FormCadastroBodyEndereco,
  FormCadastroBodyObservacao,
  GridFive,
  GridFour,
  GridThree,
  GridTwo,
} from './style';
import { Input } from '../../Input/InputDefault';
import { InputCNPJ } from '../../Input/InputCNPJ';
// import { Loading } from "../../Loading/Loading";
import { toast } from 'react-toastify';
import axios from 'axios';
// import { useHistory } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { InputCEP } from '../../Input/InputCEP';
import { Button } from '@material-ui/core';
import { InputDominio } from '../../Input/InputDominio';
// import { SelectOption } from "../../Input/Select";
import { ContainerFormHeader } from './ContainerFormHeader';
import { TextAreaInput } from '../../Input/TextAreaInput';
import fetchJsonp from 'fetch-jsonp';
import { api } from '../../../Services/api';
import { useEffect } from 'react';
import { SelectEstado } from '../../Input/SelectEstado';
import { SelectCidade } from '../../Input/SelectCidade';
import { SelectTipoPessoa } from '../../Input/SelectTipoPessoa';
import {
  alternaLayoutCliente,
  alternaLayoutRevenda,
} from '../../../utils/FormularioCliente/actionsFormularioCliente';
import { SelectRevenda } from '../../Input/SelectRevenda';
import { removeMaskTelefoneECep } from '../../../utils/mask';
import { TopButton } from '../../Button/TopButton';

export const DadosCliente = ({
  dadosFormulario,
  setDadosFormulario,
  handleChange,
}) => {
  const cliente = useParams();

  const [revenda, setRevenda] = useState([
    {
      id: '',
      nome_fantasia: '',
    },
  ]);

  const [tipoPessoa, setTipoPessoa] = useState([
    {
      id: '',
      descricao: '',
    },
  ]);

  const [estados, setEstados] = useState([
    {
      id: '',
      descricao: '',
    },
  ]);

  const [tipoPessoaSelecionado, setTipoPessoaSelecionado] = useState('1');

  // eslint-disable-next-line
  const [estadoSelecionado, setEstadoSelecionado] = useState('');

  // eslint-disable-next-line
  const [cidadeSelecionada, setCidadeSelecionada] = useState('');

  // eslint-disable-next-line
  const [revendaSelecionada, setRevendaSelecionada] = useState('');

  const [cidades, setCidades] = useState([
    {
      id: '',
      descricao: '',
    },
  ]);

  // const [situacaoTheOne, setSituacaoTheOne] = useState(false);
  // const [situacaoPhoenix, setSituacaoPhoenix] = useState(false);
  // const [situacaoPedido, setSituacaoPedido] = useState(false);
  // const [modalIsOpen, setIsOpen] = useState(false);
  const [displayDados, setDisplayDados] = useState(true);
  const [displayEndereco, setDisplayEndereco] = useState(true);
  const [displayObservacao, setDisplayObservacao] = useState(false);
  const [bloqueiaCampos, setBloqueiaCampos] = useState(false);

  useEffect(() => {
    function getRevenda() {
      api
        .get('pessoarevenda')
        .then((response) => {
          setRevenda(response.data);
        })
        .catch((error) => {});
    }
    function getTipoPessoa() {
      api
        .get('tipopessoa')
        .then((response) => {
          setTipoPessoa(response.data);
        })
        .catch((error) => {});
    }
    function getEstados() {
      api
        .get('estados')
        .then((response) => {
          setEstados(response.data);
        })
        .catch((erro) => {});
    }

    function setComboTipoPessoa() {
      dadosFormulario.id_tipo_pessoa === 1
        ? (document.getElementById('selectPessoa').value = '1')
        : (document.getElementById('selectPessoa').value = '2');
    }

    getRevenda();
    getTipoPessoa();
    getEstados();
    setComboTipoPessoa();

    if (cliente.id !== undefined) {
      setBloqueiaCampos(true);
    } else {
      setBloqueiaCampos(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    tipoPessoaSelecionado === '1'
      ? alternaLayoutCliente()
      : alternaLayoutRevenda();
  }, [tipoPessoaSelecionado]);

  useEffect(() => {
    function getCidades() {
      api
        .get(`cidades?estado=${estadoSelecionado}`)
        .then((response) => {
          setCidades(response.data);
        })
        .catch((erro) => {});
    }
    getCidades();
  }, [estadoSelecionado]);

  function verificaPessoaExiste(cnpj) {
    const resposta = api
      .get('/pessoa', { headers: { cnpj } })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          return true;
        }
      })
      .catch(() => {
        return false;
      });

    return resposta;
  }

  async function verificaCNPJ() {
    if (!dadosFormulario.cnpj) {
      toast.error('CNPJ vazio!');
      return;
    }

    const valores = {
      cnpj: dadosFormulario.cnpj.replace(/[^0-9]/g, ''),
    };

    if (valores.cnpj.length < 14) {
      toast.error('CNPJ Inválido!');
      return;
    }

    const pessoaExiste = await verificaPessoaExiste(valores.cnpj);

    if (pessoaExiste === true) {
      toast.error('Já existe um cadastro com o CNPJ informado!');
      return;
    }

    await fetchJsonp(`https://www.receitaws.com.br/v1/cnpj/${valores.cnpj}`)
      .then((res) => {
        res
          .json()
          .then((json) => {
            if (json.status === 'ERROR') {
              toast.error('Erro ao consultar CNPJ');
              return false;
            }

            if (json.situacao !== 'ATIVA') {
              toast.error(
                'O CNPJ DO CLIENTE: ' +
                  json.nome +
                  ' TEM PENDÊNCIAS COM A RECEITA FEDERAL OU ESTÁ INATIVADO!',
              );
              return;
            }

            toast.warn(
              'Alguns campos foram preenchidos automaticamente, por favor verifique!',
            );
            try {
              setDadosFormulario({
                ...dadosFormulario,
                cnpj: dadosFormulario.cnpj.replace(/[^0-9]/g, ''),
              });

              const respRec = Object(json);
              dadosFormulario.razao_social = respRec.nome;
              dadosFormulario.fantasia = respRec.fantasia
                ? respRec.fantasia
                : respRec.nome;
              dadosFormulario.cnae = removeMaskTelefoneECep(
                respRec.atividade_principal[0].code,
              );
              dadosFormulario.cep = removeMaskTelefoneECep(respRec.cep);
              dadosFormulario.telefone = removeMaskTelefoneECep(
                respRec.telefone,
              );
              dadosFormulario.email = respRec.email;

              setDadosFormulario(dadosFormulario);
              verificaCEP();
            } catch (e) {
              toast.error('Erro ao atribuir valores da empresa');
            }
          })
          .catch((err) => {
            toast.error(`Erro!', ${err.message}`);
            return false;
          });
      })
      .catch((erro) => {
        toast.error(`Erro ao obter dados do CNPJ!`);
        return false;
      });
  }

  async function verificaCEP() {
    if (!dadosFormulario.cep) {
      toast.error('CEP Vazio!');
      return;
    }

    const valores = {
      cep: dadosFormulario.cep.replace(/[^0-9]/g, ''),
    };
    await axios
      .get(`https://viacep.com.br/ws/${valores.cep}/json/`)
      .then(async (response) => {
        console.log(response);
        if (response.data.erro) {
          toast.error('CEP Inválido');
          return;
        }

        dadosFormulario.cep = response.data.cep.replace(/[^0-9]/g, '');
        dadosFormulario.endereco = response.data.logradouro;
        dadosFormulario.complemento = response.data.complemento;
        dadosFormulario.bairro = response.data.bairro;

        await api.get(`cidades?ibge=${response.data.ibge}`).then((res) => {
          dadosFormulario.cidade = res.data[0].id;
          dadosFormulario.estado = res.data[0].id_estado;
        });

        await api.get(`cidades?ibge=${response.data.ibge}`).then((res) => {
          dadosFormulario.id_cidade = res.data[0].id;
          dadosFormulario.id_estado = res.data[0].id_estado;
        });

        setDadosFormulario({
          ...dadosFormulario,
        });

        document.getElementById('selectEstado').value = dadosFormulario.estado;
        document.getElementById('selectCidade').value = dadosFormulario.cidade;
      })
      .catch((error) => {
        toast.error('Verifique o CEP consultado!');
      });
  }

  return (
    <>
      <Container>
        <ContainerFormHeader
          titulo="Dados"
          displayBtn={displayDados}
          setDisplay={setDisplayDados}
        />
        <FormCadastroBodyDados
          className="conteudo_form"
          style={displayDados ? { display: 'block' } : { display: 'none' }}
        >
          <GridTwo>
            <GridThree>
              <Input
                type="number"
                label={`Código ${dadosFormulario.id ? dadosFormulario.id : ''}`}
                disabled={true}
                handleChange={handleChange}
              />

              <InputCNPJ
                cnpj={dadosFormulario.cnpj}
                handleChange={handleChange}
                disabled={bloqueiaCampos}
              />

              <Button
                onClick={() => {
                  verificaCNPJ();
                }}
              >
                {' '}
                Consultar CNPJ
              </Button>
            </GridThree>

            <SelectTipoPessoa
              id="tcadastro"
              nome="Tipo de Cadastro *"
              options={tipoPessoa}
              selecionado={
                dadosFormulario.id_tipo_pessoa
                  ? dadosFormulario.id_tipo_pessoa
                  : 0
              }
              setData={setTipoPessoaSelecionado}
            />
          </GridTwo>

          <GridTwo>
            <Input
              handleChange={handleChange}
              label="Razão Social *"
              name="razao_social"
              id="razao_social"
              value={dadosFormulario.razao_social}
              maxLength={100}
              required
            />

            <Input
              type="text"
              label="Nome Fantasia"
              name="fantasia"
              id="fantasia"
              handleChange={handleChange}
              value={dadosFormulario.fantasia}
              maxLength={100}
            />
          </GridTwo>

          <GridTwo>
            <Input
              label="CNAE"
              handleChange={handleChange}
              name="cnae"
              id="cnae"
              mask={'9999-9/99'}
              value={
                dadosFormulario.cnae && dadosFormulario.cnae !== ''
                  ? dadosFormulario.cnae
                  : ''
              }
            />

            <Input
              type="text"
              id="inscricao_estadual"
              label="Inscrição Estadual"
              handleChange={handleChange}
              name="inscricao_estadual"
              mask={'99999999999999'}
              value={dadosFormulario.inscricao_estadual}
            />
          </GridTwo>

          <GridTwo>
            <Input
              label="Inscrição Municipal"
              handleChange={handleChange}
              name="inscricao_municipal"
              mask={'99999999999'}
              value={dadosFormulario.inscricao_municipal}
            />

            <SelectRevenda
              idHtml="select_form_revenda"
              nome="Selecione a Revenda"
              options={revenda}
              setData={setRevendaSelecionada}
              selecionado={
                dadosFormulario.id_pessoa_revenda
                  ? dadosFormulario.id_pessoa_revenda
                  : 0
              }
            />
          </GridTwo>

          <InputDominio
            type="text"
            label="Subdomínio"
            handleChange={handleChange}
            name="subdominio"
            disabled={true}
            value={dadosFormulario.subdominio}
          />
        </FormCadastroBodyDados>
      </Container>

      <Container>
        <ContainerFormHeader
          titulo="Endereço"
          displayBtn={displayEndereco}
          setDisplay={setDisplayEndereco}
        />
        <FormCadastroBodyEndereco
          className="conteudo_form"
          style={displayEndereco ? { display: 'block' } : { display: 'none' }}
        >
          <GridFive>
            <InputCEP
              handleChange={handleChange}
              value={dadosFormulario.cep ? dadosFormulario.cep : ''}
              name="inputCEP"
            />

            <Button
              onClick={() => {
                verificaCEP();
              }}
            >
              Consultar CEP
            </Button>

            <Input
              type="text"
              label="Logradouro*"
              name="endereco"
              id="endereco"
              handleChange={handleChange}
              value={dadosFormulario.endereco}
              maxLength={100}
            />

            <Input
              label="Número *"
              name="endereco_nro"
              id="endereco_nro"
              // type="number"
              handleChange={handleChange}
              value={dadosFormulario.endereco_nro}
              mask={'9999'}
            />
          </GridFive>

          <GridTwo>
            <Input
              type="text"
              label="Complemento"
              name="complemento"
              handleChange={handleChange}
              value={dadosFormulario.complemento}
              maxLength={100}
            />

            <Input
              type="text"
              label="Bairro *"
              name="bairro"
              id="bairro"
              handleChange={handleChange}
              value={dadosFormulario.bairro}
              maxLength={100}
            />
          </GridTwo>

          <GridFour>
            <SelectEstado
              id="estado"
              nome="Estado *"
              options={estados}
              selecionado={dadosFormulario.id_estado}
              setData={setEstadoSelecionado}
            />

            <SelectCidade
              id="cidade"
              nome="Cidade *"
              options={cidades}
              selecionado={
                dadosFormulario.id_cidade ? dadosFormulario.id_cidade : 0
              }
              setData={setCidadeSelecionada}
            />

            <Input
              label="Telefone *"
              name="telefone"
              id="telefone"
              handleChange={handleChange}
              value={dadosFormulario.telefone}
              mask={
                removeMaskTelefoneECep(dadosFormulario.telefone).substr(
                  2,
                  1,
                ) === '9'
                  ? '(99) 99999-9999'
                  : '(99) 9999-9999'
              }
            />

            <Input
              type="text"
              label="Responsável"
              name="responsavel"
              handleChange={handleChange}
              value={dadosFormulario.responsavel}
              maxLength={100}
            />
          </GridFour>

          <GridTwo>
            <Input
              type="text"
              label="E-mail *"
              name="email"
              id="email"
              handleChange={handleChange}
              value={dadosFormulario.email}
              maxLength={100}
            />

            <Input
              idHtml="input_form_site"
              type="text"
              label="Site corporativo"
              name="site"
              handleChange={handleChange}
              value={dadosFormulario.site}
              maxLength={100}
            />
          </GridTwo>
        </FormCadastroBodyEndereco>
      </Container>

      <Container>
        <ContainerFormHeader
          titulo="Observações"
          displayBtn={displayObservacao}
          setDisplay={setDisplayObservacao}
        />
        <FormCadastroBodyObservacao
          className="conteudo_form"
          style={displayObservacao ? { display: 'block' } : { display: 'none' }}
        >
          <TextAreaInput
            name="observacao"
            handleChange={handleChange}
            value={dadosFormulario.observacao}
          />
        </FormCadastroBodyObservacao>
      </Container>

      <TopButton />
    </>
  );
};
